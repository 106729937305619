import { useState, useEffect } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// components
import ThesaurusVoiceSelector from './thesaurusVoiceSelector/ThesaurusVoiceSelector';
import { Player } from '../../../generics/player/Player';

// mui
import { Button, Grid, TextField, CircularProgress, IconButton, Switch, Stack } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

// interfaces
import { LexiconEntry } from '../../../../models/lexiconEntry.model';
import { playerDefaultState } from '../../../../state/playerDefaultState';
import { getStreamDefaultState } from '../../../../state/streamDefaultState';
import { Selector } from '../../../../interfaces/Selector.interface';
import { Client } from '../../../../models/client.model';

// actions
import { partsUpdate } from '../../../../actions/parts.actions';
import { setPartsLexiconTags } from '../../../../helpers/articles/runtimeProcessArticle/flagParts';
import { loadConfig, saveLexiconEntry, deleteLexiconEntry } from '../../../../actions/config.actions';
import { handlePlayPauseThesaurus } from '../../../generics/player/playerHandlers';

// styled components
import { ThesaurusSettingsGrid } from './styles/ThesurusGrid';
import { FormControlLabelSwitch } from '../audioEditorPage/styles/EditorPageStyle';
import { LanguageSelector } from '../audioEditorPage/rightPanel/languageSelector/LanguageSelector';
import { Voice } from '../../../../interfaces/article/Voice.interface';
import { thesaurusVoiceUpdate } from '../../../../actions/voices.actions';

interface Props {
  lexiconEntryEditing?: LexiconEntry;
  language?: string;
}

export const ThesaurusPanel = ({ lexiconEntryEditing, language }: Props) => {
  const dispatch = useDispatch();

  // state selector
  const article = useSelector((state: Selector) => state.article);
  const articleParts = useSelector((state: Selector) => state.parts);
  const auth = useSelector((state: Selector) => state.auth);
  const config = useSelector((state: Selector) => state.config.config);
  const thesaurusVoiceId = useSelector((state: Selector) => state.voices.thesaurusVoiceId);
  const voiceList = useSelector((state: Selector) => state.voices.voiceList);

  const { name, role, uid } = auth;
  const { activeArticle, editor } = article;
  const { selectedPart } = editor;

  const [originalText, setOriginalText] = useState(lexiconEntryEditing?.originalText || '');
  const [replacementText, setReplacementText] = useState(lexiconEntryEditing?.translationText || '');
  const [actualReplacementText, setActualReplacementText] = useState('');
  const [isKnownLexicon, setIsKnownLexicon] = useState(false);

  // generic lexicon: only for SuperAdmin user
  const defaultGenericValue: boolean = role !== 'SuperAdmin' ? false : lexiconEntryEditing?.isGeneric || true;
  const [isGeneric, setGeneric] = useState(defaultGenericValue);

  const [isTtsDependant, setIsTtsDependant] = useState(lexiconEntryEditing?.isTtsDependant || false);
  const [originalTextPlaying, setOriginalTextPlaying] = useState(false);
  const [replacementTextPlaying, setReplacementTextPlaying] = useState(false);

  // thesaurus voice
  let voice =
    articleParts && articleParts.length > 0
      ? articleParts.filter(part => part.order === selectedPart)[0].voice
      : voiceList[0];
  if (lexiconEntryEditing?.isTtsDependant && lexiconEntryEditing?.tts && lexiconEntryEditing?.tts.length > 0) {
    voice = voiceList.filter(v => v.TTS === lexiconEntryEditing.tts)[0] ?? voice;
  }

  const [thesaurusVoice, setThesaurusVoice] = useState<Voice>(voice);
  const [lexiconEntries, setLexiconEntries] = useState<LexiconEntry[]>(
    config?.filter(element => element.name.toLowerCase() === 'lexicon')[0]?.data as LexiconEntry[],
  );

  const [playerState, setPlayerState] = useState({ ...playerDefaultState });
  const [streamState, setStreamState] = useState(getStreamDefaultState(language ? language : editor.selectedLanguage));

  useEffect(() => {
    setStreamState({
      enabled: true,
      config: config,
      voices: voiceList,
      parts: articleParts,
      selectedPart: editor.selectedPart ? editor.selectedPart : 0,
      selectedLanguage: language ? language : editor.selectedLanguage,
    });
  }, [config, voiceList, articleParts, editor.selectedPart, editor.selectedLanguage, language]);

  useEffect(() => {
    setPlayerState({ ...playerDefaultState });
    // eslint-disable-next-line
  }, [activeArticle.id]);

  useEffect(() => {
    if (thesaurusVoiceId && thesaurusVoiceId > -1) {
      setThesaurusVoice(voiceList.filter(voice => voice.id === thesaurusVoiceId)[0]);
    } else {
      let voice =
        articleParts && articleParts.length > 0
          ? articleParts.filter(part => part.order === selectedPart)[0].voice
          : voiceList[0];

      if (lexiconEntryEditing?.isTtsDependant && lexiconEntryEditing?.tts && lexiconEntryEditing?.tts.length > 0) {
        voice = voiceList.filter(v => v.TTS === lexiconEntryEditing.tts)[0] ?? voice;
      }

      if (voice) {
        setThesaurusVoice(voice);
        dispatch(thesaurusVoiceUpdate(voice.id));
      }
    }
  }, [
    thesaurusVoiceId,
    voiceList,
    articleParts,
    selectedPart,
    dispatch,
    lexiconEntryEditing?.isTtsDependant,
    lexiconEntryEditing?.tts,
  ]);

  useEffect(() => {
    setLexiconEntries(config?.filter(element => element.name.toLowerCase() === 'lexicon')[0]?.data as LexiconEntry[]);
  }, [config, editor.selectedLanguage]);

  useEffect(() => {
    const selectedVoiceTts = voiceList.filter(v => v.id === thesaurusVoiceId)[0]?.TTS;

    // check if new thesaurus entry original text already exists in thesaurus
    let lexicon = lexiconEntries?.filter(
      l =>
        l.originalText === originalText &&
        l.language === editor.selectedLanguage &&
        l.isGeneric === isGeneric &&
        l.tts === selectedVoiceTts,
    );
    if (originalText !== '' && lexicon.length === 1) {
      setIsKnownLexicon(true);
      setActualReplacementText(lexicon[0].translationText);
    } else {
      setIsKnownLexicon(false);
    }
  }, [originalText, lexiconEntries, isGeneric, editor.selectedLanguage, thesaurusVoiceId, voiceList]);

  useEffect(() => {
    if (!playerState.playing && originalTextPlaying) setOriginalTextPlaying(false);
    if (!playerState.playing && replacementTextPlaying) setReplacementTextPlaying(false);
  }, [playerState, originalTextPlaying, replacementTextPlaying]);

  const handleSaveThesaurusEntry = () => {
    const selectedVoiceTts = voiceList.filter(v => v.id === thesaurusVoiceId)[0]?.TTS;

    // edit mode: overwrite existing entry
    if (lexiconEntryEditing?.id && lexiconEntryEditing?.id > 0) {
      let newList: LexiconEntry[] = [];

      for (let lexicon of lexiconEntries) {
        if (lexicon.id === lexiconEntryEditing.id) {
          const entryToSave: LexiconEntry = new LexiconEntry(
            lexicon.id,
            originalText,
            replacementText,
            isGeneric,
            isGeneric ? null : new Client(uid, name),
            activeArticle.language ? activeArticle.language.code : editor.selectedLanguage,
            isTtsDependant,
            isTtsDependant ? selectedVoiceTts : null,
          );
          newList.push(entryToSave);

          if (articleParts && Symbol.iterator in Object(articleParts)) {
            dispatch(partsUpdate([...setPartsLexiconTags([...articleParts], newList)]));
          }

          saveLexiconEntry(entryToSave).then(action => {
            dispatch(action);
            loadConfig().then(action => dispatch(action));
          });
        } else {
          newList.push(lexicon);
        }
      }
    } else {
      // new entry
      let updatedList = [...lexiconEntries];

      // check if new thesaurus entry's original text already exists
      const languageCode: string = language ? language : editor.selectedLanguage;

      const lexicon = lexiconEntries?.filter(
        l =>
          l.originalText === originalText &&
          l.language === languageCode &&
          l.isGeneric === isGeneric &&
          l.tts === (!l.isTtsDependant ? null : selectedVoiceTts),
      );

      // override confirmation if entry already exists
      if (lexicon.length === 1) {
        Swal.fire({
          title: 'Warning',
          text: `The source '${originalText}' already exist in the thesaurus. Do you want to override it'?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Override',
          cancelButtonText: 'Cancel',
        }).then(result => {
          return new Promise(() => {
            if (result.isConfirmed) {
              // update previous entry
              const lexiconToDelete = lexicon[0];

              updatedList = updatedList.filter(lexiconToKeep => lexiconToKeep !== lexiconToDelete);

              const entryToSave: LexiconEntry = new LexiconEntry(
                lexiconToDelete.id,
                originalText,
                replacementText,
                isGeneric,
                isGeneric ? null : new Client(uid, name),
                languageCode,
                isTtsDependant,
                isTtsDependant ? selectedVoiceTts : null,
              );

              // add the new one
              updatedList.push(entryToSave);

              if (articleParts && Symbol.iterator in Object(articleParts)) {
                dispatch(partsUpdate([...setPartsLexiconTags([...articleParts], updatedList)]));
              }

              saveLexiconEntry(entryToSave).then(action => {
                dispatch(action);
                loadConfig().then(action => dispatch(action));
              });
            }
          });
        });
      }
      // otherwise save directly
      else {
        const entryToSave: LexiconEntry = new LexiconEntry(
          0,
          originalText,
          replacementText,
          isGeneric,
          isGeneric ? null : new Client(uid, name),
          languageCode,
          isTtsDependant,
          isTtsDependant ? selectedVoiceTts : null,
        );

        updatedList.push(entryToSave);

        if (articleParts && Symbol.iterator in Object(articleParts)) {
          dispatch(partsUpdate(setPartsLexiconTags(articleParts, updatedList)));
        }
        saveLexiconEntry(entryToSave).then(action => {
          dispatch(action);
          loadConfig().then(action => dispatch(action));
        });
      }
    }
  };

  const handleDeleteThesaurusEntry = () => {
    let updatedList = [...lexiconEntries];
    const selectedVoiceTts = voiceList.filter(v => v.id === thesaurusVoiceId)[0]?.TTS;

    // check if new thesaurus entry original text already exists
    const lexicon = lexiconEntries?.filter(
      l =>
        l.originalText === originalText &&
        l.language === editor.selectedLanguage &&
        l.isGeneric === isGeneric &&
        l.tts === selectedVoiceTts,
    );

    if (lexicon.length === 1) {
      const lexiconToDelete = lexicon[0];
      updatedList = updatedList.filter(lexiconToKeep => lexiconToKeep !== lexiconToDelete);

      if (articleParts && Symbol.iterator in Object(articleParts)) {
        dispatch(partsUpdate(setPartsLexiconTags(articleParts, updatedList)));
      }
      deleteLexiconEntry(lexiconToDelete.id).then(action => {
        dispatch(action);
        loadConfig().then(action => dispatch(action));
      });
    }
  };

  const handlePlayPauseOriginalText = () => {
    setOriginalTextPlaying(true);
    handlePlayPauseThesaurus(originalText, thesaurusVoice.id, thesaurusVoice.languageId, streamState, setPlayerState);
  };

  const handlePlayPauseReplacementText = () => {
    setReplacementTextPlaying(true);
    handlePlayPauseThesaurus(
      replacementText,
      thesaurusVoice.id,
      thesaurusVoice.languageId,
      streamState,
      setPlayerState,
    );
  };

  return (
    <>
      <Player playerState={playerState} setPlayerState={setPlayerState} />

      <ThesaurusSettingsGrid>
        <form>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <LanguageSelector />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <ThesaurusVoiceSelector />
          </Grid>
          <Grid item id="top-row" xs={12} sx={{ pl: 1, mt: 2 }}>
            <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                fullWidth
                id="source"
                data-test="lexicon-input-source"
                label="Source text"
                size="small"
                variant="standard"
                name={originalText}
                value={originalText}
                onChange={(e: any) => setOriginalText(e.target.value)}
              />

              <IconButton
                aria-label="startPause"
                data-test="lexicon-btn-playsource"
                onClick={() => handlePlayPauseOriginalText()}
                color="primary"
                disabled={
                  originalText === '' || (playerState.playing && (!playerState.duration || playerState.isBuffering))
                }
                sx={{ fontSize: 32, mt: 1 }}
              >
                {originalTextPlaying && playerState.playing && (!playerState.duration || playerState.isBuffering) ? (
                  <CircularProgress data-test="lexicon-playsource-progress" color="secondary" size={32} />
                ) : originalTextPlaying && playerState.playing ? (
                  <PauseCircleIcon data-test="lexicon-playsource-pauseicon" fontSize="inherit" />
                ) : (
                  <PlayCircleFilledWhiteIcon data-test="lexicon-playsource-playicon" fontSize="inherit" />
                )}
              </IconButton>
            </Stack>
          </Grid>
          <Grid item id="bottom-row" xs={12} sx={{ pl: 1, mt: 2 }}>
            <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                fullWidth
                id="replacement"
                data-test="lexicon-input-replacement"
                label="Replacement text"
                size="small"
                variant="standard"
                name={replacementText}
                value={replacementText}
                helperText={isKnownLexicon ? 'Existing Replacement: ' + actualReplacementText : undefined}
                onChange={(e: any) => setReplacementText(e.target.value)}
              />

              <IconButton
                aria-label="startPause"
                data-test="lexicon-btn-playreplacement"
                onClick={() => handlePlayPauseReplacementText()}
                color="primary"
                disabled={
                  replacementText === '' || (playerState.playing && (!playerState.duration || playerState.isBuffering))
                }
                sx={{ fontSize: 32, marginTop: '5px' }}
              >
                {replacementTextPlaying && playerState.playing && (!playerState.duration || playerState.isBuffering) ? (
                  <CircularProgress data-test="lexicon-playreplacement-progress" color="secondary" size={32} />
                ) : replacementTextPlaying && playerState.playing ? (
                  <PauseCircleIcon data-test="lexicon-playreplacement-pauseicon" fontSize="inherit" />
                ) : (
                  <PlayCircleFilledWhiteIcon data-test="lexicon-playreplacement-playicon" fontSize="inherit" />
                )}
              </IconButton>
            </Stack>
          </Grid>
        </form>

        <Grid
          container
          direction="column"
          sx={role === 'SuperAdmin' ? { mt: 2 } : {}}
          visibility={role === 'SuperAdmin' ? 'visible' : 'hidden'}
        >
          <Grid item xs={3}>
            <FormControlLabelSwitch
              control={
                <Switch
                  data-test="lexicon-switch-client"
                  checked={!isGeneric}
                  disabled={originalText === '' || replacementText === ''}
                  onChange={() => setGeneric(switchState => !switchState)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Client specific"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          sx={role === 'SuperAdmin' ? { mt: 2 } : {}}
          visibility={role === 'SuperAdmin' ? 'visible' : 'hidden'}
        >
          <Grid item xs={3}>
            <FormControlLabelSwitch
              control={
                <Switch
                  data-test="lexicon-switch-tts"
                  checked={isTtsDependant}
                  disabled={originalText === '' || replacementText === ''}
                  onChange={() => setIsTtsDependant(switchState => !switchState)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={`TTS specific (${voiceList
                ?.filter(v => v.id === thesaurusVoiceId)[0]
                ?.TTS?.toUpperCase()
                .slice(0, 1)})`}
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '15px' }}>
          <Button
            data-test="lexicon-btn-save"
            size="medium"
            startIcon={<SaveIcon fontSize="medium" />}
            onClick={handleSaveThesaurusEntry}
            disabled={originalText === '' || replacementText === ''}
          >
            Save
          </Button>
          <Button
            data-test="lexicon-btn-delete"
            size="medium"
            startIcon={<DeleteIcon fontSize="medium" />}
            onClick={handleDeleteThesaurusEntry}
            disabled={originalText === '' || !isKnownLexicon}
          >
            Delete
          </Button>
        </Grid>
      </ThesaurusSettingsGrid>
    </>
  );
};
